import React, { useRef, useEffect } from 'react'
import BrandieAd from '../video/BrandieAd.mp4'

/* eslint-disable */
const VideoPlayer = ({ src, type }) => {
  const videoRef = useRef(null)

  useEffect(() => {
    const videoElement = videoRef.current

    const handlePlayPause = (entries) => {
      const [entry] = entries

      if (entry.isIntersecting) {
        videoElement.play()
      } else {
        videoElement.pause()
      }
    }

    const observer = new IntersectionObserver(handlePlayPause, {
      threshold: 0.5 
    })

    if (videoElement) {
      observer.observe(videoElement)
    }

    return () => {
      if (videoElement) observer.unobserve(videoElement)
    }
  }, [])

  return (
    <div
      id='video-block'
      style={{
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        position: 'relative'
      }}
    >
      <video
        ref={videoRef}
        controls={false}
        muted
        loop
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover' // Adjust fitting mode if needed
        }}
      >
        <source src={BrandieAd} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
    </div>
  )
}

export default VideoPlayer
